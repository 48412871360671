@keyframes x-loader
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

x-loader
  display: block
  font-size: 0
  line-height: 0
  img,
  svg
    width: 16px
    height: 16px
    animation: x-loader linear infinite 1s
