import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-divider',
  styleUrl: 'x-divider.sass',
})
export class XDivider {
  @Prop({ reflect: true }) direction: 'horizontal' | 'vertical' = 'horizontal';
  @Prop({ reflect: true }) size: string;

  render() {
    return <Host></Host>;
  }
}
