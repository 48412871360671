x-divider
  display: block
  background-color: var(--divider-dark-grey)

  &[direction="horizontal"]
    width: 100%
    height: 1px
  &[direction="vertical"]
    width: 1px
    height: 100%
    @each $key, $value in $gaps
      &[size="#{$key}"]
        height: #{$value}px
